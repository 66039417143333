$themes-root-el: '.skel';

@function theme-name-to-css-selector($theme) {
    @return '[data-theme="#{$theme}"]';
}

/**
 * Render the background gradient based on the theme received.
 * All the gradients will be also rotated 45deg (according to the designs)
 */
@mixin themes-background-linear-gradient-by-name($theme) {
    background: linear-gradient(45deg, map-get($theme, 'primary'), map-get($theme, 'secondary'));
}

/**
 * Render the background primary color based on the theme received.
 */
@mixin themes-background-by-name($theme, $facet) {
    background: map-get($theme, $facet);
}

/**
 * Use the theme primary color to style the color of a specific DOM element
 */
@mixin themes-color-by-name($theme, $facet) {
    color: map-get($theme, $facet);
}

/**
 * Loop all the themes using the $themes-by-name variable,
 * if the body has that class to set the component will inherit the color property from the selected theme
 */
@mixin themes-all-colors-by-root-class($facet: 'primary', $has-hover: false) {
    @each $name, $theme in $themes-by-name {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            @include themes-color-by-name($theme, $facet);

            @if $has-hover {
                @include has-focus(true) {
                    color: darken(map-get($theme, 'primary'), 10%);
                }
            }
        }
    }
}

/**
 * Loop all the themes using the $themes-by-name variable
 * if the body has that class to set the component inherit the background gradient color from the selected theme
 */
@mixin themes-all-linear-gradient-background-by-root-class() {
    @each $name, $theme in $themes-by-name {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            @include themes-background-linear-gradient-by-name($theme);
        }
    }
}

/**
 * Loop all the themes using the $themes-by-name variable
 * if the body has that class to set the component inherit the background primary color from the selected theme
 */
@mixin themes-all-background-by-root-class($facet: 'primary') {
    @each $name, $theme in $themes-by-name {
        @include at-root($themes-root-el, theme-name-to-css-selector($name)) {
            @include themes-background-by-name($theme, $facet);
        }
    }
}

/**
 * Helper function if we want to override the default themes in specific components
 */
@mixin themes-default-styles() {
    @include at-root($themes-root-el, theme-name-to-css-selector('default')) {
        @content;
    }
}

