/// Monochrome special decoration colors
///
$monochromes: (
    'white': #ffffff,
    'black': #000000,
    'black-gray': #333333,
    'dark-gray': #5c5c5c,
    'warm-gray': #9d9d9d,
    'silver': #a5a5a5,
    'light-gray': #e5e5e5,
    'paper-white': #fbfbfb,
);

$themes: (
    'default': ('primary': #5c5c5c, 'secondary': #333333),
    'theme-1': ('primary': #fdc330, 'secondary': #ee7330),
    'theme-2': ('primary': #ee7330, 'secondary': #fdc330),
    'theme-3': ('primary': #f34893, 'secondary': #f5b5d3),
    'theme-4': ('primary': #962a86, 'secondary': #bca3ce),
    'theme-5': ('primary': #005ca9, 'secondary': #3ea1dc),
    'theme-6': ('primary': #339078, 'secondary': #91bc62),
    'theme-7': ('primary': #91bc62, 'secondary': #339078),
    'theme-8': ('primary': #a5a5a5, 'secondary': #5c5c5c),
);

$theme-default: map-get($themes, 'default');
$theme-1: map-get($themes, 'theme-1');
$theme-2: map-get($themes, 'theme-2');
$theme-3: map-get($themes, 'theme-3');
$theme-4: map-get($themes, 'theme-4');
$theme-5: map-get($themes, 'theme-5');
$theme-6: map-get($themes, 'theme-6');
$theme-7: map-get($themes, 'theme-7');
$theme-8: map-get($themes, 'theme-8');

// The site themes listed semantically. Probably each page of the site will have a different theme
// For designers and backenders it's easier to identify the themes giving them a name
$themes-by-name: (
    'economics': $theme-1,
    'informatics': $theme-2,
    'electronics': $theme-3,
    'automation': $theme-4,
    'mechanics': $theme-5,
    'plant_construction': $theme-6,
    'construction': $theme-7,
    'further_education': $theme-default,
    'toolroom': $theme-8,
    'plastic': $theme-8,
    'default': $theme-default,
);

/// Single purpose colors
///
$labels: (
    'text': map-get($monochromes, 'black-gray'),
    'link': #005ca9,
    'selection': #dddddd,
);

/// Signal colors
///
$signals: (
    'info': #0000ff,
    'error': #d0021b,
    'warning': #ffff00,
    'success': #00b37d,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    label: $labels,
    signal: $signals,
    theme-default: $theme-default,
    theme-1: $theme-1,
    theme-2: $theme-2,
    theme-3: $theme-3,
    theme-4: $theme-4,
    theme-5: $theme-5,
    theme-6: $theme-6,
    theme-7: $theme-7,
    theme-8: $theme-8,
);
