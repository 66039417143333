.tiles {
    // Vars
    $tiles-margin-bottom: 36px;
    $title-margin-top: 72px;
    $title-margin-bottom: 24px;

    // Support

    // Module
    & {
        .tiles--title {
            flex: 1 1 100%;
            margin-top: $title-margin-top - $tiles-margin-bottom;
            margin-bottom: $title-margin-bottom;

            @include type--h3;
        }

        .tiles--item {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            margin-bottom: $tiles-margin-bottom;
            overflow: hidden;

            @include grid--span((ratio: 4/12, mq: '>sm'));
            @include grid--span((ratio: 12/12, mq: '<=sm'));

            // reset the title top position for the first row
            &:first-child {
                .tiles--title {
                    margin-top: 0;
                }
            }

            @include mq('>sm') {
                &:nth-child(2),
                &:nth-child(3) {
                    .tiles--title {
                        margin-top: 0;
                    }
                }
            }
        }

        .tiles--links-list {
            width: 100%;
        }
    }

    // Facets

    // States
}
