.logo-list {
    // Vars
    $logos-margin-bottom-mobile: 24px;
    $logos-marign-bottom-desktop: 36px;
    $container-vertical-padding-mobile: 36px;
    $container-vertical-padding-desktop: 96px;

    // Support

    // Module
    & {
        background: $c--monochrome-light-gray;
        padding-top: $container-vertical-padding-desktop;
        padding-bottom: $container-vertical-padding-desktop - $logos-marign-bottom-desktop;

        @include mq('<=sm') {
            padding-top: $container-vertical-padding-mobile;
            padding-bottom: $container-vertical-padding-mobile - $logos-margin-bottom-mobile;
        }

        .logo-list--title {
            margin-bottom: 24px;
        }

        .logo-list--item {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 140px;
            margin-bottom: $logos-marign-bottom-desktop;

            @include grid--span((ratio: 3/12, mq: '>sm'));
            @include grid--span((ratio: 6/12, mq: '<=sm'));

            @include mq('<=sm') {
                height: 85px;
                margin-bottom: $logos-margin-bottom-mobile;
            }
        }

        .logo-list--link {
            display: inline-block;
            width: 100%;
        }

        .logo-list--image {
            max-width: 100%;
        }
    }

    // Facets

    // States
}
