.cms-generic-placeholder {
    // Vars

    // Support

    // Module
    & {
        width: 100%;

        .cms-generic-placeholder--plugin {
            width: 100%;
            margin-bottom: 72px;

            @include mq('<=sm') {
                margin-bottom: 36px;
            }
        }
    }

    // Facets
    & {
        .cms-generic-placeholder--plugin {
            // implementing https://app.asana.com/0/137886543980536/1202552987997411
            // this is a bit of a hack but this feature is an ad-hoc Nino's request and we don't have any concept for it
            &:first-of-type:not(.cms-generic-placeholder--plugin__dyn-intro-image):not(.cms-generic-placeholder--plugin__dyn-hero-video):not(.cms-generic-placeholder--plugin__dyn-hero-image) {
                margin-top: 72px;

                @include mq('<=sm') {
                    margin-top: 36px;
                }
            }

            &.cms-generic-placeholder--plugin__dyn-quote:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    // States
}
