.not-found-page {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        width: 100%;

        .not-found-page--container {
            flex: 1 0 auto;
        }

        .not-found-page--row {
            text-align: center;
            justify-content: center;
        }

        .not-found-page--title {
            margin-bottom: 8px;
        }

        .not-found-page--col {
            margin-top: 80px;
            margin-bottom: 80px;

            @include grid--span((
                ratio: 10/12,
                mq: '>md'
            ));
        }
    }

    // Facets

    // States
}
