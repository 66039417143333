.contact-form {
    // Vars

    // Support

    // Module
    & {
        .contact-form--title,
        .contact-form--description {
            margin-bottom: 16px;
        }

        .contact-form--col {
            @include grid--span((ratio: 5 / 12, mq: '>xl'));
            @include grid--span((ratio: 8 / 12, mq: ('>md' '<=xl')));
            @include grid--span((ratio: 1/1, mq: '<=md'));
        }
    }

    // Facets

    // States
}
