////
/// Button facets and state generation library
////
/// Dependencies and components
///
@import 'btn__vars';
@import 'btn__helpers';
@import 'btn__facets';

/// Apply button styles to their respective selectors
/// @param {string} $type - A button-facet name defined in `$button-facets`
///
@mixin btn--style($type) {
    &:not(:disabled):not([data-disabled]) {
        // normal (and not disabled)
        & {
            @include has-focus(false) {
                @include _btn--apply-style($type, 'base');
                text-decoration: none;
            }
        }

        // focus (and not inert)
        &:not([data-inert]) {
            @include has-focus(true) {
                @include _btn--apply-style($type, 'focus');
                text-decoration: none;
            }
        }
    }

    // disabled
    &:disabled,
    &[data-disabled] {
        @include _btn--apply-style($type, 'disabled');
        text-decoration: none;
        cursor: inherit;
    }

    // inert
    &[data-inert] {
        cursor: default;
    }
}

/// All applicable btn facets, sizes and special types
///
.btn {
    // Vars

    // Support

    // Module
    & {
        @include btn--base();
    }

    // Facets
    & {
        // Sizes
        &.btn__small {
            @include btn--size-small();
        }

        &, &.btn__medium {
            @include btn--size-medium();
        }

        // Facets
        &.btn__primary {
            @include btn--primary();
        }

        &.btn__secondary {
            @include btn--secondary();
        }

        // Special
        &.btn__has-grid {
            @include btn--has-grid();
        }

        &.btn__bubble {
            @include btn--bubble();
        }

        &.btn__back-link {
            @include btn--back-link();
        }
    }

    // States
    & {
        @include _btn--define-facets();
    }
}
