.hero-image {
    // Vars

    // Support

    // Module
    & {
        .hero-image--container {
            position: relative;
            margin: 0 auto;
            height: 25vw;
            min-height: 180px;
            max-width: $sz--max-fullscreen-content-width;
        }


        .hero-image--image {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    // Facets

    // States
}
