.hero-video {
    // Vars

    // Support

    // Module
    & {
        .hero-video--container {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            margin: 0 auto;
            height: calc(100vh - 174px);
            min-height: 320px;
            max-width: $sz--max-fullscreen-content-width;
            color: $c--monochrome-white;

            @include mq('<=sm') {
                height: calc(100vh - 120px);
            }
        }

        .hero-video--title-container.hero-video--title-container {
            margin-bottom: 0;
        }

        .hero-video--next-button {
            cursor: pointer;
            padding: 16px;
        }

        .hero-video--icon {
            position: relative;
            transform: rotate(90deg);
            margin-bottom: 24px;

            @include mq('<=lg') {
                margin-bottom: 16px;
            }
        }

        .hero-video--title {
            position: relative;
            text-align: center;
            line-height: 1.2;
            white-space: break-spaces;

            font-weight: $fw--bold;
            font-size: 7.6rem;

            @include mq('<=lg') {
                font-size: 5.6rem;
            }

            @include mq('<=md') {
                line-height: 1.33;
                font-size: 3.3rem;
            }
        }

        .hero-video--player {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    // Facets

    // States
}
