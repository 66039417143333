.two-column-rich-text {
    // Vars

    // Support

    // Module
    & {
        .two-column-rich-text--col {
            @include grid--span((ratio: 1/2, mq: '>lg'));
            @include grid--span((ratio: 1/1, mq: '<=lg'));
        }
    }

    // Facets

    // States
}
