.main-footer {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-wrap: wrap;
        padding-top: 48px;
        padding-bottom: 48px;
        background: $c--monochrome-black;
        color: $c--monochrome-white;
    }

    // Facets

    // States
}
