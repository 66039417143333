.image-slider {
    // Vars

    // Support

    // Module
    & {
        padding-top: 36px;
        padding-bottom: 36px;

        @include mq('xs') {
            padding-top: 16px;
            padding-bottom: 16px;
        }

        .image-slider--image-wrapper {
            margin: 0 auto;
            width: 100%;

            @include mq('>sm') {
                width: 10 / 12 * 100%;
            }
        }

        .image-slider--image {
            width: 100%;
        }
    }

    // Facets

    & {
        @include themes-all-linear-gradient-background-by-root-class;

        @include themes-default-styles {
            background: $c--monochrome-light-gray;
        }

        &.image-slider__is-interactive {
            padding-bottom: 24px;
        }
    }

    // States
}
