.main-header {
    // Vars

    // Support

    // Module
    & {
        .main-header--top {
            background: $c--monochrome-black-gray;
            padding: 12px 0;

            @include mq('<=sm') {
                display: none;
            }
        }

        .main-header--top-row {
            justify-content: flex-end;
        }

        .main-header--bottom {
            position: relative;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        }

        .main-header--bottom-row {
            padding: 20px 0;

            @include mq('>sm') {
                align-items: center;
            }
        }

        .main-header--logo {
            @include grid--span((ratio: 3/12, mq: '>sm'));
        }

        .main-header--menu {
            @include grid--span((ratio: 9/12, mq: '>sm'));
        }

        .main-header--menu {
            @include mq('<=sm') {
                display: none;
            }
        }

        .main-header--metanav {
            margin-right: 20px;
        }

        .main-header--hamburger {
            z-index: z-index(hamburger);
            position: relative;
            display: none;
            margin-left: auto;

            @include mq('<=sm') {
                display: flex;
                align-items: center;
            }
        }
    }

    // Facets

    // States
}
