/// Field styles
///
@mixin form-field--primary-input($sz--padding-top, $sz--padding-x, $sz--padding-bottom, $sz--border-width) {
    // Vars

    // Support

    // Module
    & {
        .form-field--input-container {
            position: relative;
        }

        .form-field--title-label {
            cursor: pointer;
            transition: $trs--common-props;
        }

        .form-field--input {
            display: inline-block;
            width: 100%;
            padding: $sz--padding-top $sz--padding-x $sz--padding-bottom;
            border-width: $sz--border-width;
            border-style: solid;
            border-radius: $sz--border-radius-base;
            line-height: 1.3;
            cursor: text;
            transition: $trs--common-props;

            @include placeholder() {
                transition: $trs--common-props;
            }
        }
    }

    // States
    & {
        // Base
        @include form-field--apply-states(()) {
            @include form-field--primary-input-base();
        }

        // Focus
        @include form-field--apply-states(('disabled': false, 'focus': true)) {
            @include form-field--primary-input-focus();
        }

        // Disabled
        @include form-field--apply-states(('disabled': true, 'focus': default)) {
            @include form-field--primary-input-disabled();
        }

        // Error
        @include form-field--apply-states(('error': true, 'focus': default)) {
            @include form-field--primary-input-error();
        }
    }
}

/// Base
///
@mixin form-field--primary-input-base() {
    .form-field--input {
        color: $c--label-text;
        background-color: $c--monochrome-white;
        border-color: $c--monochrome-warm-gray;

        @include placeholder() {
            color: $c--monochrome-silver;
        }
    }
}

/// Focus
///
@mixin form-field--primary-input-focus() {
    .form-field--title-label {
        color: $c--label-text;
    }

    .form-field--input {
        border-color: $c--monochrome-black-gray;
    }
}

/// Disabled
///
@mixin form-field--primary-input-disabled() {
    .form-field--title-label {
        @include is-selectable(false);
        cursor: default;
    }

    .form-field--input {
        @include is-selectable(false);
        color: $c--monochrome-silver;
        background-color: $c--monochrome-paper-white;
        border-color: $c--monochrome-light-gray;
        cursor: default;

        @include placeholder() {
            color: transparent;
        }
    }
}

/// Error
///
@mixin form-field--primary-input-error() {
    .form-field--input {
        border-color: $c--signal-error;
    }

    .form-field--title-label {
        color: $c--signal-error;
    }
}
