.image {
    // Vars

    // Support

    // Module
    & {
        background-size: cover;
        background-position: center center;
    }

    // Facets

    // States
}
