.app-logo {
    // Vars

    // Support

    // Module
    & {
        .app-logo--img {
            width: 100%;
            max-width: 240px;

            @include mq('<=sm') {
                max-width: 217px;
            }
        }
    }

    // Facets

    // States
}
