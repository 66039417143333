.form-success {
    // Vars

    // Support

    // Module
    & {
        margin-top: 72px;

        @include mq('<=sm') {
            margin-top: 36px;
        }

        .form-success--title,
        .form-success--description {
            color: $c--signal-success;
            margin-bottom: 16px;
        }

        .form-success--title {
            @include type--h2;
        }

        .form-success--description {
            @include type--lead;
        }

        .form-success--col {
            @include grid--span((ratio: 10 / 12, mq: '>lg'));
        }
    }

    // States
}
