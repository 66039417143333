/// The z-index values of elements
///
$layers: (
    content: 0,
    swiper-nav: 1,
    tile-content: 1,
    social: 2,
    lateral-menu: 9,
    hamburger: 10,
);

/// Returns a z-index stored within the `$layers` map, which stores z-indexes of all central ui elements
/// @param {string} $layer - Key that is searched for
/// @return {number} A z-index number
///
@function z-index($layer: 'content') {
    @if not map-has-key($layers, $layer) {
        @error 'The layer `#{$layer}` is not contained within the $layers map!';
    }

    @return map-get($layers, $layer);
}
