.language-menu {
    // Vars

    // Support
    @mixin link-active() {
        @include ff--base-bold;

        @include mq('<=sm') {
            color: $c--monochrome-black-gray;
        }
    }

    // Module
    & {
        .language-menu--collection {
            display: flex;
        }

        .language-menu--item {
            text-transform: uppercase;
            padding-right: 4px;
        }

        .language-menu--item ~ .language-menu--item {
            padding-left: 6px;
            padding-right: 6px;
        }

        .language-menu--link {
            color: $c--monochrome-white;

            @include ff--base-medium;

            @include has-focus(true) {
                color: $c--monochrome-light-gray;
            }
        }
    }

    // Facets
    & {
        .language-menu--link {
            &.language-menu--link__active {
                @include link-active;
            }
        }

        &.language-menu__lateral-menu {
            .language-menu--collection {
                justify-content: center;
            }

            .language-menu--link {
                padding: 20px;
                color: $c--monochrome-silver;

                &.language-menu--link__active {
                    @include link-active;
                }
            }

            .language-menu--item {
                border-left: none;
            }
        }
    }

    // States
}
