.form-submit {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        width: 100%;

        .form-submit--wrap {
            display: flex;
            flex-flow: column nowrap;
            align-items: flex-end;
            width: 100%;

            .grecaptcha-badge { // stylelint-disable-line plugin/selector-bem-pattern
                position: relative;
                box-shadow: none !important; // stylelint-disable-line declaration-no-important
            }

            > div {
                display: flex;
            }

            @include mq('<=lg') {
                > div {
                    flex-flow: column nowrap;
                    align-items: flex-end;
                }

                > div > div {
                    margin-bottom: 10px;
                }
            }

            @include mq('>lg') {
                > div {
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                }
            }
        }
    }

    // Facets

    // States
}
