/// Looks for a style expression within a `$button-facets` map and returns the given style
/// @param {string} $facet - The facet name to look for
/// @param {string} $prop - The state property to look for
///
@mixin _btn--apply-style($facet, $prop: 'base') {
    $styles: map-deep-get($button-facets, $facet, $prop);

    @each $style-key, $style-value in $styles {
        @if not $style-value {
            $style-value: map-deep-get($button-facets, $facet, 'base', $style-key);
        }

        #{$style-key}: #{$style-value};
    }
}

/// Works through `$button-facets` and applies their styles accordingly
///
@mixin _btn--define-facets() {
    @each $facet-key, $facet-value in $button-facets {
        &.btn__#{$facet-key} {
            @include btn--style($facet-key);
        }
    }
}
