.lateral-menu {
    // Vars

    // Support

    // Module
    & {
        z-index: z-index(lateral-menu);
        position: fixed;
        display: flex;
        flex-direction: column;
        overflow: auto;

        height: 100vh;
        width: 100vw;
        background: $c--monochrome-white;

        transition: transform $trs--base;
        transform: translate(100%, 0);

        @include ios-native-scrolling;

        .lateral-menu--content {
            display: flex;
            flex: 1 1 100%;
            justify-content: center;
            flex-direction: column;

            padding: 20px;
        }

        .lateral-menu--logo {
            padding: 20px 0;
        }
    }

    // Facets

    & {
        &.lateral-menu__is-open {
            transform: translate(0, 0);
        }
    }

    // States
}
