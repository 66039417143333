.video {
    // Vars

    // Support

    // Module
    & {
        padding-top: 36px;
        padding-bottom: 36px;

        @include mq('xs') {
            padding-top: 18px;
            padding-bottom: 18px;
        }

        .video--row {
            justify-content: center;
        }

        .video--col {
            @include grid--span((ratio: 12/12));
        }

        .video--iframe-wrapper {
            position: relative;
            overflow: hidden;
            padding-top: 56.25%;
        }

        .video--iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    // Facets


    // States
}
