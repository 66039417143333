.quote {
    // Vars

    // Support

    // Module
    & {
        & {
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: 0;
                display: block;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.25);
                max-width: $sz--max-fullscreen-content-width;
                transform: translate(-50%, 0);
            }
        }


        .quote--text {
            position: relative;
            padding: 15vw 0 48px;
            color: $c--monochrome-white;

            @include grid--span((ratio: 11 / 20, mq: '>sm'));
            @include grid--span((ratio: 20 / 20, mq: '<=sm'));

            @include mq('<=sm') {
                padding-top: 96px;
            }
        }

        .quote--image {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            max-width: $sz--max-fullscreen-content-width;
            transform: translate(-50%, 0);

            @supports (object-fit: cover) {
                object-fit: cover;
                object-position: top;
                height: 100%;
            }
        }

        .quote--main {
            margin-bottom: 16px;

            @include type--h2;

            @include mq('<=md') {
                @include type--h3;
            }
        }

        .quote--author {
            @include ff--base-medium;
        }
    }

    // Facets

    // States
}
