.detail-page {
    // Vars

    // Support

    // Module
    & {
        width: 100%;
    }

    // Facets

    // States
}
