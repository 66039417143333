.rich-text {
    // Vars

    // Support

    // Module
    & {
        .rich-text--col {
            @include grid--span((ratio: 9 / 12, mq: '>lg'));
        }

        h1, h2, h3 {
            margin-bottom: 16px;
        }

        p, ul, ol {
            margin-bottom: 24px;
        }

        .lead {
            @include type--lead;
        }
    }

    // Facets

    // States
}
