/// Font-styles, usable for project-wide styling of text instances
///
@mixin ff--base-regular($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--normal;
    font-style: normal;
}

@mixin ff--base-medium($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--normal;
    font-style: oblique;
}

@mixin ff--base-bold($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--bold;
    font-style: normal;
}
