.call-to-action {
    // Vars

    // Support

    // Module
    & {
        background: $c--monochrome-light-gray;

        @include grid--root();

        .call-to-action--title {
            margin-bottom: 12px;
            width: 100%;

            @include type--h2;
        }

        .call-to-action--text {
            margin-bottom: 48px;
            width: 100%;

            @include type--lead;
        }

        .call-to-action--content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding-top: 72px;
            padding-bottom: 72px;

            @include grid--span((ratio: 4/6, mq: '>md'));
            @include grid--span((ratio: 10/12, mq: '<=md'));

            @include mq('<=sm') {
                padding-top: 24px;
                padding-bottom: 24px;
            }
        }

        .call-to-action--col {
            display: flex;
            justify-content: center;
            align-items: center;

            @include grid--span((ratio: 6/12, mq: '>md'));
            @include grid--span((ratio: 12/12, mq: '<=md'));
        }

        .call-to-action--image {
            width: 100%;

            @include mq('<=md') {
                height: 0;
                padding-top: 9/16 * 100%;
            }
        }
    }

    // Facets

    & {
        .call-to-action--col {
            &.call-to-action--col__content {
                @include mq('<=md') {
                    order: 1;
                }
            }

            &.call-to-action--col__image {
                align-items: stretch;
            }
        }
    }

    // States
}
