.tile {
    // Vars
    $lateral-padding: 20px;
    $vertical-padding: 24px;

    // Support

    // Module
    & {
        position: relative;
        display: inline-block;
        background-position: center;
        background-size: cover;

        width: 100%;
        height: 160px;
        overflow: hidden;

        .tile--content {
            z-index: z-index(tile-content);
            position: relative;
            width: 100%;
            height: 100%;

            padding: $vertical-padding $lateral-padding;
        }

        .tile--title {
            color: $c--monochrome-white;
            /* stylelint-disable */
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            /* stylelint-enable */

            @include type--h3;
        }

        .tile--subtitle {
            color: $c--monochrome-white;
            font-size: $fz--large;
        }

        .tile--arrow {
            position: absolute;
            bottom: $vertical-padding;
            color: $c--monochrome-white;
        }

        .tile--arrow {
            left: $lateral-padding;
            transition: transform $trs--base;
        }
    }

    // Facets

    // States
    & {
        @include has-focus(true) {
            .tile--content {
                &::before {
                    transform: skewX(-45deg) translate(-20%, 0);
                }
            }

            .tile--arrow {
                transform: translate(20px, 0);
            }

            .tile--cube {
                opacity: 0;
            }
        }

        @each $name, $theme in $themes-by-name {
            &.tile__#{$name} {
                .tile--content {
                    background: rgba(map-get($theme, 'primary'), 0.65);
                }
            }
        }
    }
}
