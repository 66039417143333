.code-snippet {
    // Vars

    // Support

    // Module
    & {
        .code-snippet--title {
            margin-bottom: 16px;
        }

        .code-snippet--code {
            width: 100%;
            overflow: hidden;
        }
    }

    // Facets

    // States
}
