.intro-image {
    // Vars

    // Support

    // Module
    & {
        z-index: -1;
        display: block;
        width: 100%;
        height: auto;

        @include mq('<=lg') {
            position: relative;
            width: 150%;
            transform: translate(-25%, 0);
        }

        @include mq('<=sm') {
            width: 300%;
            transform: translate(-35%, 0);
        }
    }

    // Facets

    // States
}
