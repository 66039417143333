/// Font weights used throughout the project
///
$fw--normal: 400;
$fw--bold: 500;

/// The project's font families
///
$ff--base: 'TTNorms';

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Helvetica, Arial;
// stylelint-enable value-keyword-case
