$sz--list-icon-width: 12px;
$sz--list-icon-gutter: 6px;
$sz--list-padding: ($sz--list-icon-width + $sz--list-icon-gutter);
$sz--list-vertical-spacing: 8px;

.skel {
    ul, ol {
        // Reset styling
        &.u-reset { // stylelint-disable-line selector-no-qualifying-type
            &, > li {
                list-style: none;
            }
        }

        // Basic styling
        &:not(.u-reset) {
            padding-left: $sz--list-padding;
            list-style: none;

            > li {
                position: relative;
                display: inline-block;
                width: 100%;

                > * { // stylelint-disable selector-max-universal
                    margin-bottom: 0;
                }

                ~ li {
                    margin-top: $sz--list-vertical-spacing;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    right: 100%;
                    display: inline-block;
                    width: $sz--list-padding;
                    height: auto;
                    font-size: inherit;

                    color: currentColor;
                }
            }
        }
    }

    ul {
        &:not(.u-reset) {
            > li {
                &::before {
                    content: '\2022';
                    text-align: center;

                    @include themes-all-colors-by-root-class;

                    @include themes-default-styles {
                        color: $c--label-text;
                    }
                }
            }
        }
    }

    ol {
        &:not(.u-reset) {
            counter-reset: li;

            > li {
                &::before {
                    content: counter(li) '.';
                    counter-increment: li;
                    text-align: center;

                    @include themes-all-colors-by-root-class;

                    @include themes-default-styles {
                        color: $c--label-text;
                    }
                }
            }
        }
    }
}
