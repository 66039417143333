.main-menu {
    // Vars

    // Support
    @mixin item-active() {
        color: $c--monochrome-black-gray;
        border-bottom: 2px solid currentColor;
    }

    // Module
    & {
        display: flex;
        flex: 1 1 100%;
        justify-content: flex-end;

        .main-menu--collection {
            display: flex;
        }

        .main-menu--item ~ .main-menu--item {
            margin-left: 48px;

            @include mq('<=lg') {
                margin-left: 24px;
            }
        }

        .main-menu--link {
            color: $c--monochrome-silver;

            @include ff--base-medium;

            @include has-focus(true) {
                @include item-active;
            }
        }
    }

    // Facets

    & {
        .main-menu--link {
            &.u-router-link-exact-active {
                @include item-active;
            }
        }

        &.main-menu__vertical {
            flex-direction: column;

            .main-menu--collection {
                flex-direction: column;
            }

            .main-menu--item {
                margin: 0 auto 40px;
            }
        }
    }

    // States
}
