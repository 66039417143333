/// Base mixin for buttons
///
@mixin btn--base {
    border: $sz--border-width-base solid transparent;
    border-radius: $sz--border-radius-base;
    display: inline-block;
    cursor: pointer;

    transition: transition-props($trs--base, color, border-color, background-color, opacity);

    @include ff--base-medium;
}

/// Button sizes
///
@mixin btn--size-medium() {
    padding: 11px 16px 9px;
    font-size: $fz--medium;

    &.btn__bubble {
        padding: 10px;
    }
}

@mixin btn--size-small() {
    padding: 9px 10px 7px;
    font-size: $fz--small;

    &.btn__bubble {
        padding: 4px;
    }
}

/// Button facets
///
@mixin btn--primary() {
    //
}

@mixin btn--secondary() {
    //
}

/// Special types
///
@mixin btn--has-grid() {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include grid--root((
        row: 'wrap',
        cols: ('icon', 'text'),
    ));
    @include grid--space((
        gutter: 8px,
        row: 'wrap',
        cols: ('icon', 'text'),
    ));

    .btn--wrap {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
    }

    .btn--text {
        // NOTE: Depending on the font, we need a correction value to visually center our text:
        position: relative;
        transform: translateY(1px);
        text-align: left;
    }
}

@mixin btn--bubble() {
    min-width: 0;
    border-radius: 50%;

    .btn--icon {
        margin: 0;
    }
}

@mixin btn--back-link() {
    padding: 0;

    .btn--text {
        @include type--lead;
    }
}
