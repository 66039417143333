.footer-bottom {
    // Vars

    // Support
    @mixin active-link() {
        color: $c--monochrome-light-gray;
    }

    // Module
    & {
        flex: 1 1 100%;
        padding: 24px 0;
        color: $c--monochrome-white;

        @include type--lead;

        .footer-bottom--row {
            @include mq('>md') {
                align-items: flex-end;
                justify-content: space-between;
            }
        }

        .footer-bottom--left {
            color: $c--monochrome-light-gray;

            @include mq('<=md') {
                flex: 1 1 100%;
                margin-top: 32px;
            }
        }

        .footer-bottom--right {
            @include mq('<=md') {
                order: -1;
                flex: 1 1 100%;
                margin-top: 24px;
            }
        }

        .footer-bottom--title {
            font-weight: $fw--bold;
        }

        .footer-bottom--paragraph {
            white-space: pre-line;
            margin-bottom: 48px;

            @include mq('<=md') {
                margin-bottom: 32px;
            }

            a {
                /* stylelint-disable-next-line declaration-no-important */
                color: $c--monochrome-white !important;
            }
        }

        .footer-bottom--collection {
            display: flex;
            flex-wrap: wrap;
        }

        .footer-bottom--item {
            margin-right: 12px;

            @include mq('<=md') {
                flex: 1 1 100%;
                margin-bottom: 2px;
            }
        }

        .footer-bottom--link {
            @include has-focus(true) {
                @include active-link;
            }
        }
    }

    // Facets
    & {
        .footer-bottom--link {
            &.u-router-link-exact-active {
                @include active-link;
            }
        }
    }

    // States
}
