// Not BEM here :(
/* stylelint-disable plugin/selector-bem-pattern */

.swiper-slider {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        padding-bottom: 32px;

        @include mq('xs') {
            padding-bottom: 24px;
        }

        .swiper-slider--pagination {
            bottom: 0;

            @include mq('xs') {
                transform: scale(0.75);
            }

            .swiper-pagination-bullet {
                opacity: 0.5;
                background: $c--monochrome-white;

                @include themes-default-styles {
                    background: $c--monochrome-black-gray;
                }
            }
        }

        .swiper-slider--nav {
            z-index: z-index(swiper-nav);
            position: absolute;
            top: 50%;

            display: none;
            transform: translate(0, -50%);

            @include mq('>sm') {
                display: block;
            }
        }

        .swiper-slider--icon {
            @include themes-all-colors-by-root-class;

            @include themes-default-styles {
                color: $c--monochrome-white;
            }
        }
    }

    // Facets
    & {
        .swiper-slider--pagination {
            .swiper-pagination-bullet {
                &.swiper-pagination-bullet-active {
                    opacity: 1;
                    background: $c--monochrome-white;

                    @include themes-default-styles {
                        background: $c--monochrome-black-gray;
                    }
                }
            }
        }

        .swiper-slider--nav {
            &.swiper-slider--nav__next {
                right: 0;
            }

            &.swiper-slider--nav__pref {
                left: 0;
            }

            &.swiper-button-disabled {
                pointer-events: none;
                opacity: 0.25;
            }
        }
    }

    // States
}
