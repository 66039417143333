.social-links {
    // Vars

    // Support

    // Module
    & {
        & {
            z-index: z-index(social);
            position: fixed;
            right: 24px;
            top: 25%;

            @include mq('>lg') {
                right: 48px;
            }
        }

        .social-links--collection {
            display: flex;
            flex-direction: column;
            gap: 4px;
            transform: translate(-50%, 0);
        }

        .social-links--item {
            pointer-events: auto;
            flex: 0 0 32px;
            width: 32px;
            margin: 0 0 0 auto;
        }

        .social-links--link {
            position: relative;
            color: #00b37d;
            display: inline-block;
            border-radius: 100%;
            padding: 0.8rem;
            background: $c--monochrome-white;
        }
    }

    // Facets

    // States
}
