.meta-navigation {
    // Vars

    // Support
    @mixin link-active {
        @include ff--base-bold;
    }

    // Module
    & {
        .meta-navigation--collection {
            display: flex;
        }

        .meta-navigation--item ~ .meta-navigation--item {
            margin-left: 32px;
        }

        .meta-navigation--link {
            color: $c--monochrome-white;

            @include ff--base-bold;

            @include has-focus(true) {
                color: $c--monochrome-light-gray;
            }
        }
    }

    // Facets
    & {
        .meta-navigation--link {
            &.u-router-link-exact-active {
                @include link-active;
                color: $c--monochrome-white;
            }
        }
    }

    // States
}
