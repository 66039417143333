.intro-teaser {
    // Vars

    // Support

    // Module
    & {
        .intro-teaser--back-link {
            margin-bottom: 24px;
        }

        .intro-teaser--title {
            margin-bottom: 16px;
        }

        .intro-teaser--buttons {
            margin-top: 24px;
        }

        .intro-teaser--description {
            white-space: pre-line;
        }

        .intro-teaser--button {
            margin-right: 16px;

            @include mq('<=sm') {
                margin-bottom: 12px;
            }
        }

        .intro-teaser--col {
            @include grid--span((ratio: 9 / 12, mq: '>lg'));
        }
    }

    // Facets
    & {
        &.intro-teaser__base {
            padding: 72px 0;
            color: $c--monochrome-white;

            @include mq('<=sm') {
                padding: 36px 0;
            }

            @include themes-all-linear-gradient-background-by-root-class;

            @include themes-default-styles {
                padding: 0;
                color: $c--label-text;
                background: transparent;
            }
        }
    }

    // States
}
