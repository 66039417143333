.lazy-route {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1 1 100%;
        min-height: 25vh;
    }

    // Facets

    // States
}
