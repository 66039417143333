.link-list {
    // Vars

    // Support

    // Module
    & {
        .link-list--item {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .link-list--link {
            display: flex;
            align-items: center;

            @include type--copy;
            @include ff--base-medium;
            @include themes-all-colors-by-root-class('primary', true);

            @include themes-default-styles {
                color: $c--label-text;
            }
        }

        .link-list--icon {
            margin-left: 8px;
        }
    }

    // Facets

    // States
}
