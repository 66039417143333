/// Field styles
///
@mixin form-field--primary-actions($sz--padding-x) {
    // Vars
    $c--action-focus: $c--label-text;
    $num--max-actions: 3;
    $sz--icon-width: 28px;
    $sz--icon-margin: 8px;

    // Support

    // Module
    & {
        .form-field--input-container {
            // Padding right for the actions, depending on the action count
            // Limit action count to 3, that should be enough for every case...
            @for $i from 1 through $num--max-actions {
                &[data-action-count='#{$i}'] {
                    .form-field--input {
                        padding-right: ($sz--icon-margin * 2) + ($i * $sz--icon-width);
                    }
                }
            }
        }

        .form-field--actions {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 $sz--icon-margin 0 $sz--padding-x;
        }

        .form-field--action {
            pointer-events: auto;

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            width: $sz--icon-width;
            height: auto;
            padding: 2px;

            cursor: pointer;
            transition: color $trs--base;
        }
    }

    // States
}
