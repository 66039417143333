&.grid__base {
    // Vars
    $scrollbar-offset: 16px;
    $grid--base-container-sizes: (
        (mq: 'xs', container-size: none),
        (mq: 'sm', container-size: none),
        (mq: 'md', container-size: none),
        (mq: 'lg', container-size: ($screen-lg-min - $scrollbar-offset - 8px)),
        (mq: 'xl', container-size: ($screen-xl-min - $scrollbar-offset - 4px)),
        (mq: 'xxl', container-size: ($screen-xxl-min - $scrollbar-offset - 8px)),
    ) !global;
    $grid--base-spacing: (
        (mq: 'xs', gutter: 16px, edge: 16px),
        (mq: 'sm', gutter: 36px, edge: 36px),
        (mq: 'md', gutter: 36px, edge: 36px),
        (mq: 'lg', gutter: 36px, edge: 36px),
        (mq: 'xl', gutter: 36px, edge: 36px),
        (mq: 'xxl', gutter: 36px, edge: 36px),
    ) !global;

    // Support

    // Module
    & {
        @include grid--root();

        @each $value in $grid--base-container-sizes {
            @include grid--constrain($value);
        }

        @each $value in $grid--base-spacing {
            @include grid--space($value);
        }

        .grid--container.grid--container__fluid {
            max-width: none;
        }
    }

    // Facets

    // States
}
